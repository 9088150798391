import { useLocalisation } from 'hooks';
import { Box, Container, Flex, Text } from '@chakra-ui/react';

export const CountdownBlackFriday = () => {
  const { t } = useLocalisation();

  return (
    <Box
      bgColor="black"
      display="flex"
      alignItems="center"
      px="1rem"
      py={{ base: '0.35rem', md: '0.45rem' }}
    >
      <Container variant="lg">
        <Flex gap="0.5rem" alignItems="center" justifyContent="center">
          <Text textStyle="body16" color="white" fontWeight="700">
            {t.timerBlackFriday.text1}{' '}
            <Text as="span" color="#FFB422">
              {t.timerBlackFriday.text2}
            </Text>
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};
