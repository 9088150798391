import type { FC, PropsWithChildren } from 'react';
import { Flex, SystemStyleObject } from '@chakra-ui/react';
import Head from 'next/head';
import { Footer, Header } from 'components';

interface Props extends PropsWithChildren {
  header?: JSX.Element;
  footer?: JSX.Element;
  sx?: SystemStyleObject;
}

export const Layout: FC<Props> = ({
  header = <Header />,
  footer = <Footer />,
  sx,
  children,
}) => (
  <>
    <Head>
      <title>Memowrite</title>
    </Head>
    <Flex
      flexDirection="column"
      minHeight="100vh"
      position="relative"
      justifyContent="space-between"
    >
      {header}
      <Flex
        justifyContent={{ base: 'flex-start', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
        gap={{ base: '1rem', md: '2rem' }}
        flex="1"
        sx={sx}
      >
        {children}
      </Flex>
      {footer}
    </Flex>
  </>
);
