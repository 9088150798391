import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image as ChakraImage,
  Text,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { Product } from 'types';
import { DiscountBlackFriday, Divider, Icon } from 'components';
import { DEFAULT_PRODUCT_IMAGE } from 'config';
import { formatPrice, getCurrency } from 'utils';
import { useLocalisation } from 'hooks';

interface Props {
  selectedProduct: Product;
  showBlackFridayDiscount?: boolean;
}

export const OrderSummaryGift: FC<Props> = ({
  selectedProduct,
  showBlackFridayDiscount = false,
}) => {
  const { t } = useLocalisation();
  const { trials } = selectedProduct;

  const currency = getCurrency(selectedProduct.prices[0].currency_id);

  const oldPrice = useMemo(() => {
    const value =
      trials.length > 0
        ? trials[0].old_amount
        : selectedProduct.prices[0].price;

    return value.toFixed(2);
  }, [selectedProduct]);

  const finalPrice = useMemo(() => {
    const value =
      trials.length > 0
        ? trials[0].amount
        : selectedProduct.prices[0].final_price;

    return value.toFixed(2);
  }, [selectedProduct]);

  const discount = useMemo(() => {
    if (trials.length > 0 && trials[0].old_amount) {
      const priceDifference = trials[0].old_amount - trials[0].amount;
      const percentage = (
        (priceDifference / trials[0].old_amount) *
        100
      ).toFixed(0);

      return {
        priceDifference: priceDifference.toFixed(2),
        percentage,
      };
    }

    if (selectedProduct.prices[0].discount_percent) {
      const priceDifference = (
        selectedProduct.prices[0].price - selectedProduct.prices[0].final_price
      ).toFixed(2);
      const percentage = selectedProduct.prices[0].discount_percent * 100;

      return {
        priceDifference,
        percentage,
      };
    }

    if (
      selectedProduct.prices[0].price !== selectedProduct.prices[0].final_price
    ) {
      const priceDifference =
        selectedProduct.prices[0].price - selectedProduct.prices[0].final_price;
      const percentage = (
        (priceDifference / selectedProduct.prices[0].price) *
        100
      ).toFixed(0);

      return {
        priceDifference: priceDifference.toFixed(2),
        percentage,
      };
    }

    return null;
  }, [selectedProduct]);

  const productImage = selectedProduct.properties.find(
    p => p.name === 'image',
  )?.value;

  const OrderSummaryDetails = () => (
    <>
      <Divider />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
        w="100%"
        my="1.5rem"
      >
        <Flex alignItems="center" gap="1rem">
          <ChakraImage src={productImage || DEFAULT_PRODUCT_IMAGE} w="4rem" />
          <Flex flexDir="column" gap="0.25rem">
            <Text textStyle="body16" color="black">
              {t.orderSummary.textGift1}
            </Text>
            <Text textStyle="body14" color="neutral.700">
              {t.orderSummary.textGift2}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir="column">
          {oldPrice !== finalPrice && (
            <Text
              textStyle="body16"
              color="red.700"
              textDecoration="line-through"
              textAlign="right"
            >
              {currency}
              {formatPrice(oldPrice)}
            </Text>
          )}
          <Text
            textStyle="body16"
            color="green.300"
            fontWeight="500"
            textAlign="right"
          >
            {currency}
            {formatPrice(finalPrice)}
          </Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDir="column" mt="1.5rem" gap="0.5rem" color="purpleDark">
        {discount && (
          <Flex flexDir="column" gap="1rem">
            {showBlackFridayDiscount && (
              <Box display={{ base: 'none', md: 'block' }}>
                <DiscountBlackFriday
                  discount={`${currency}${formatPrice(
                    discount?.priceDifference || 0,
                  )}`}
                />
              </Box>
            )}
            <Flex justifyContent="space-between" gap="1rem" w="100%">
              <Text textStyle="body16">
                {t.common.discount}: -{discount.percentage}%
              </Text>
              <Text textStyle="body16" fontWeight="600" color="red.700">
                -{currency}
                {formatPrice(discount.priceDifference)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );

  return (
    <Flex
      bgColor="neutral.100"
      borderLeft={{ base: '0', md: '0.0625rem solid #0000001A' }}
      borderBottom={{ base: '0.0625rem solid #0000001A', md: '0' }}
      flexDirection="column"
      flex="1"
      p={{ base: '1rem', lg: '5.75rem 2rem 4rem 5rem' }}
      w={{ base: '100%', md: '50%' }}
    >
      <Box
        maxW={{ base: '100%', md: '32.5rem' }}
        mx={{ base: 'auto', md: '0' }}
        w="100%"
      >
        <Flex flexDir="column" gap={{ base: '1rem', md: '1.5rem' }}>
          <Text
            display={{ base: 'none', md: 'block' }}
            textStyle="M"
            color="purpleDark"
            fontWeight="600"
          >
            {t.orderSummary.text1}
          </Text>
          <Accordion allowToggle display={{ base: 'block', md: 'none' }}>
            <AccordionItem border="none">
              <AccordionButton
                _expanded={{ bg: 'transparent' }}
                _focus={{ boxShadow: 'none', bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                p={0}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Flex alignItems="center" gap="0.5rem">
                    <Icon src="CartGreen" />
                    <Text textStyle="M" color="purpleDark" fontWeight="600">
                      {t.orderSummary.text1}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel p="1.5rem 0 0 0">
                <OrderSummaryDetails />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box display={{ base: 'none', md: 'block' }}>
            <OrderSummaryDetails />
          </Box>
          <Divider />
          <Flex flexDir="column" gap="1rem">
            {showBlackFridayDiscount && (
              <Box display={{ base: 'block', md: 'none' }}>
                <DiscountBlackFriday
                  discount={`${currency}${formatPrice(
                    discount?.priceDifference || 0,
                  )}`}
                />
              </Box>
            )}
            <Flex justifyContent="space-between" alignItems="center">
              <Text textStyle="body16" color="purpleDark" fontWeight="600">
                {t.orderSummary.text7}
              </Text>
              <Flex flexDir="column">
                <Text
                  textStyle="body16"
                  color="red.700"
                  fontWeight="600"
                  textAlign="right"
                  textDecoration="line-through"
                >
                  {currency}
                  {formatPrice(oldPrice)}
                </Text>
                <Text
                  textStyle="M"
                  color="purpleDark"
                  fontWeight="600"
                  textAlign="right"
                >
                  {currency}
                  {formatPrice(finalPrice)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
