import { CURRENCY_SYMBOLS_MAP } from 'types';

export const formatPrice = (value: number | string): string => {
  const formattedValue = Number(value);

  return formattedValue % 1 === 0
    ? formattedValue.toFixed(0)
    : formattedValue.toFixed(2);
};

export const getCurrency = (currency: string) => {
  // @ts-ignore
  return CURRENCY_SYMBOLS_MAP[currency] || '$';
};
