import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import type { Product, User } from 'types';
import { StorageType, useStorageItem } from 'hooks';
import { StorageKey } from 'types';

export const UserContext = createContext<{
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  product?: Product;
  setProduct: Dispatch<SetStateAction<Product | undefined>>;
}>({
  setUser: () => {},
  setProduct: () => {},
});

type Props = Partial<{
  children: ReactNode;
}>;

export const UserContextProvider: FC<Props> = ({ children }) => {
  const storage = StorageType.LocalStorage;
  const [storedUser, storeUser] = useStorageItem<User>(
    storage,
    StorageKey.User,
  );
  const [storedProduct, storeProduct] = useStorageItem<Product>(
    storage,
    StorageKey.Product,
  );

  const [user, setUser] = useState<User | undefined>(storedUser);
  const [product, setProduct] = useState<Product | undefined>(storedProduct);

  const value = useMemo(
    () => ({ user, setUser, product, setProduct }),
    [user, product],
  );

  useEffect(() => {
    storeUser(user);
  }, [user, storeUser]);

  useEffect(() => {
    storeProduct(product);
  }, [product, storeProduct]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
