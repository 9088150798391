import { useCallback } from 'react';
import type { Page, TransitionOptions } from 'types';
import { Funnel } from 'types';
import { useRouter as useNextRouter } from 'next/router';
import { buildLinkTo, buildPathname, getPageByRoute } from 'utils';
import { ParsedUrlQuery } from 'querystring';

export const useRouter = () => {
  const router = useNextRouter();

  const push = useCallback(
    (args: {
      page?: Page;
      routeParams?: { [key: string]: string };
      queryParams?: ParsedUrlQuery;
      options?: TransitionOptions;
      replace?: boolean;
    }) => {
      const funnel = args.routeParams?.funnel || router.query.funnel;
      const pathname = buildPathname(
        args.page || (getPageByRoute(router.route) as Page),
        {
          ...args.routeParams,
          funnel: typeof funnel === 'string' ? funnel : Funnel.Main,
        },
      );
      const query = { ...args.queryParams };

      if (args.replace) {
        router.replace({ pathname, query }, undefined, args.options);
      } else {
        router.push({ pathname, query }, undefined, args.options);
      }
    },
    [router],
  );

  const getLinkTo = useCallback(
    (
      page?: Page,
      routeParams?: { [key: string]: string },
      queryParams?: { [key: string]: string },
    ) => {
      const funnel = routeParams?.funnel || router.query.funnel;

      return buildLinkTo(
        page || (getPageByRoute(router.route) as Page),
        {
          ...routeParams,
          funnel: typeof funnel === 'string' ? funnel : Funnel.Main,
        },
        queryParams,
      );
    },
    [router.query.funnel, router.route],
  );

  return {
    ...router,
    push,
    getLinkTo,
  };
};
