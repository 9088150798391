const getAllCookies = (): { [key: string]: string } => {
  const cookies = document.cookie.split('; ');
  const result: { [key: string]: string } = {};

  cookies.forEach(cookie => {
    const [key, value] = cookie.split('=');

    result[key] = value;
  });

  return result;
};

export const getCookiesByKeys = (keys: string[]): { [key: string]: string } => {
  const cookies = getAllCookies();
  const result: { [key: string]: string } = {};

  keys.forEach(key => {
    if (cookies[key]) {
      result[key] = cookies[key];
    }
  });

  return result;
};
