import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useLocalisation } from 'hooks';

interface Props {
  bgColor?: string;
}

export const Footer: FC<Props> = ({ bgColor = 'white' }) => {
  const { t } = useLocalisation();

  return (
    <Box
      as="footer"
      py={{ base: '1rem', md: '1.5rem' }}
      bgColor={bgColor}
      borderTop="1px solid #0000001A"
    >
      <Container variant="xl">
        <Flex
          color="neutral.785"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
          gap="0.5rem"
        >
          <Text textStyle="caption12">
            © {new Date().getFullYear()} Memowrite. {t.footer.text}
          </Text>
        </Flex>
      </Container>
    </Box>
  );
};
