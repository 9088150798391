import { colors } from '../foundations';

export const Input = {
  variants: {
    outline: {
      field: {
        color: 'neutral.900',
        background: 'white',
        border: `0.0625rem solid ${colors.neutral[200]}`,
        borderRadius: '1rem',
        _hover: {
          border: `0.0625rem solid ${colors.neutral[200]}`,
          boxShadow: `0 0 0.5rem ${colors.neutral[300]}`,
        },
        _focus: {
          boxShadow: `0 0 0.5rem ${colors.neutral[300]}`,
          border: `0.0625rem solid ${colors.neutral[200]}`,
        },
        _disabled: {
          background: 'neutral.100',
          border: `0.0625rem solid ${colors.neutral[200]}`,
          color: 'neutral.500',
          _hover: {
            boxShadow: 'unset',
          },
        },
        _invalid: {
          border: `0.0625rem solid ${colors.system.error}`,
          boxShadow: 'unset',
          _hover: {
            border: `0.0625rem solid ${colors.system.error}`,
            boxShadow: `0 0 0.5rem ${colors.neutral[300]}`,
          },
          _focus: {
            boxShadow: `0 0 0.5rem ${colors.neutral[300]}`,
            border: `0.0625rem solid ${colors.system.error}`,
          },
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        height: '3.5rem',
        padding: '1.25rem 2.875rem 0.5rem 1rem',
      },
    },
  },
};
