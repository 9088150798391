import SpinnerIcon from 'assets/icons/spinner_icon.svg';
import type { FC } from 'react';
import { memo } from 'react';
import styled from '@emotion/styled';

interface SpinnerStyledProps {
  width?: string;
}

const SpinnerStyled = styled(SpinnerIcon)<SpinnerStyledProps>`
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  width: ${({ width }) => width || '40px'};
  height: auto;
  animation: 0.75s linear infinite spinner-border;
`;

interface SpinnerProps {
  width?: string;
}

export const Spinner: FC<SpinnerProps> = memo(({ width, ...props }) => {
  return (
    <Container {...props}>
      <SpinnerStyled width={width} />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

Spinner.displayName = 'Spinner';
