import { useCallback, useEffect, useState } from 'react';
import type { ProjectConfigModel } from '@frontend/api-wrapper';
import { Page, PaymentsSuccessPayload, Product, UpsellKey, User } from 'types';
import { handleError, initialize } from 'utils';
import { useRouter, useTracking } from 'hooks';
import { PRODUCT_REFUND_KEY } from 'config';

export const usePayments = (
  config: ProjectConfigModel,
  selectedProduct: Product,
  userData: User,
  redirectToGiftSuccessPage?: boolean,
) => {
  const { push, query } = useRouter();
  const { trackSuccessfulPurchase } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { code } = userData;

  const onSuccess = useCallback(
    (data: PaymentsSuccessPayload) => {
      trackSuccessfulPurchase(selectedProduct, code, data.order_id);

      if (selectedProduct.key === PRODUCT_REFUND_KEY) {
        push({
          page: Page.Error,
          queryParams: query,
        });

        return false;
      }

      if (redirectToGiftSuccessPage) {
        window.location.href = 'https://www.getmemowrite.com/gift_success';
      }

      push({
        page: Page.Upsell,
        queryParams: { ...query, upsellKey: UpsellKey.UpsellAdditionalBooks },
      });

      return false;
    },
    [code, selectedProduct, push, query],
  );

  const onReady = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onSubmit = useCallback(() => {
    setErrorMessage('');
  }, []);

  const onError = (err: any) => {
    if (err.data) {
      setErrorMessage(err.data.message);
    } else if (err.message) {
      setErrorMessage(err.message);
    } else {
      setErrorMessage('Error occurred');
    }

    handleError(err);
  };

  useEffect(() => {
    const callbacks = {
      onSuccess,
      onError,
      onSubmit,
      onReady,
    };

    initialize(config, selectedProduct, code, callbacks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    errorMessage,
    selectedProduct,
  };
};
