import { useCountdown, useLocalisation } from 'hooks';
import { formatNumberToDwoDigits } from 'utils';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { TimeUnit } from './TimeUnit';

export const Countdown = () => {
  const { t } = useLocalisation();
  const targetDate = useMemo(() => new Date(new Date().getTime() + 900000), []);
  const { hours, minutes, seconds } = useCountdown(targetDate);

  return (
    <Box
      bgColor="black"
      display="flex"
      alignItems="center"
      px="1rem"
      py={{ base: '0.25rem', md: '0.45rem' }}
    >
      <Container variant="lg">
        <Flex gap="0.5rem" alignItems="center" justifyContent="center">
          <Text textStyle="body16" color="white">
            {t.timer.text1}
          </Text>
          <Flex pt="0.05rem">
            <TimeUnit dividerIncluded time={formatNumberToDwoDigits(hours)} />
            <TimeUnit dividerIncluded time={formatNumberToDwoDigits(minutes)} />
            <TimeUnit time={formatNumberToDwoDigits(seconds)} />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
