export const Container = {
  variants: {
    xl: {
      maxWidth: '1400px',
    },
    lg: {
      maxWidth: '1156px',
    },
    md: {
      maxWidth: '1056px',
    },
    sm: {
      maxWidth: '964px',
    },
  },
};
