import type { FC } from 'react';
import { Stack, Text } from '@chakra-ui/react';

interface Props {
  time: string;
  textColor?: string;
  unit?: string;
  dividerIncluded?: boolean;
}

export const TimeUnit: FC<Props> = ({
  time,
  textColor = 'white',
  unit,
  dividerIncluded,
}) => {
  return (
    <>
      <Stack spacing={0} m="0 !important">
        <Text textStyle="body16" fontWeight={500} color={textColor}>
          {time}
        </Text>
        {unit && (
          <Text textStyle="body16" color={textColor} opacity={0.6}>
            {unit}
          </Text>
        )}
      </Stack>
      {dividerIncluded && (
        <Text
          textStyle="body16"
          fontWeight={500}
          color={textColor}
          m="0 !important"
        >
          :
        </Text>
      )}
    </>
  );
};
