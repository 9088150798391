import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

import { Button, Container, Form, Heading, Input } from './components';
import { breakpoints, colors } from './foundations';
import { textStyles } from './styles';

export const theme = extendTheme(
  {
    textStyles,
    fonts: {
      heading: `'proxima-nova', sans-serif`,
      body: `'Reddit Sans', sans-serif`,
      side: `'Poppins', sans-serif`,
      alt: `'Merriweather', sans-serif`,
    },
    breakpoints,
    colors,
    components: {
      Button,
      Container,
      Form,
      Input,
      Heading,
      Progress: {
        baseStyle: {
          track: {
            bg: '#2D2C2C',
          },
          filledTrack: {
            borderRightRadius: '10rem',
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
);

type Theme = typeof theme;
export type { Theme };
