import { Box, Flex, Text } from '@chakra-ui/react';
import { Icon } from 'components';
import { FC } from 'react';
import { useLocalisation } from 'hooks';

interface Props {
  isSuccessPage?: boolean;
}

export const Progress: FC<Props> = ({ isSuccessPage }) => {
  const { t } = useLocalisation();

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      w="100%"
      mt="0.5rem"
      gap="0.35rem"
    >
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Box
          h={{ base: '0.75rem', md: '1rem' }}
          w={{ base: '0.75rem', md: '1rem' }}
          backgroundColor="black"
          borderRadius="50%"
        />
        <Box
          h={{ base: '0.125rem', md: '0.1875rem' }}
          bgColor="black"
          flex="1"
        />
        <Box
          h={{ base: '0.75rem', md: '1rem' }}
          w={{ base: '0.75rem', md: '1rem' }}
          bgColor="black"
          borderRadius="50%"
        />
        <Box
          h={{ base: '0.125rem', md: '0.1875rem' }}
          bgColor={isSuccessPage ? 'black' : 'neutral.775'}
          flex="1"
        />
        <Box
          h={{ base: '0.75rem', md: '1rem' }}
          w={{ base: '0.75rem', md: '1rem' }}
          bgColor={isSuccessPage ? 'black' : 'neutral.775'}
          borderRadius="50%"
        />
      </Flex>
      <Flex justifyContent={isSuccessPage ? 'flex-end' : 'center'} w="100%">
        <Icon src="CaretUpBlack" />
      </Flex>
      <Flex justifyContent="space-between" w="100%">
        <Text
          textStyle="caption12"
          color="black"
          fontWeight="500"
          fontSize={{ base: '0.625rem', md: '0.75rem' }}
          flex="1"
        >
          {t.progress.text1}
        </Text>
        <Text
          textStyle="caption12"
          color="black"
          fontWeight="500"
          fontSize={{ base: '0.625rem', md: '0.75rem' }}
          flex="1"
          textAlign="center"
        >
          {t.progress.text2}
        </Text>
        <Text
          flex="1"
          textStyle="caption12"
          color={isSuccessPage ? 'black' : 'neutral.775'}
          fontWeight="500"
          fontSize={{ base: '0.625rem', md: '0.75rem' }}
          textAlign="right"
        >
          {t.progress.text3}
        </Text>
      </Flex>
    </Flex>
  );
};
