import { textStyles } from 'theme/styles';

export const Heading = {
  variants: {
    h1: {
      ...textStyles.XL3,
    },
    h2: {
      ...textStyles.XL2,
    },
    h3: {
      ...textStyles.XL,
    },
    h4: {
      ...textStyles.L,
    },
    h5: {
      ...textStyles.M,
    },
    h6: {
      ...textStyles.S,
    },
    label: {
      ...textStyles.label,
    },
  },
  defaultProps: {
    variant: 'h3',
  },
};
