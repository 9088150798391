import type { FC } from 'react';
import { Icons } from 'assets/icons';
import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

interface Props {
  src: keyof typeof Icons;
  height?: string;
  maxHeight?: string;
  margin?: string;
  width?: string;
  maxWidth?: string;
  fill?: string;
  stroke?: string;
}

export const Icon: FC<Props> = ({ src, ...props }) => {
  const Icon = Icons[src];

  if (!Icon) return null;

  return (
    <Container {...props}>
      <Icon />
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: ${({ maxWidth }) => maxWidth || 'unset'};
  max-height: ${({ maxHeight }) => maxHeight || 'unset'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};

  svg {
    flex: 1;

    > * {
      fill: ${({ fill }) => fill};
      stroke: ${({ stroke }) => stroke};
    }
  }
`;
