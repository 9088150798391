import type { GatewayProps, Product } from 'types';

let isLoaded = false;

const loadScript = (src: any) =>
  new Promise(resolve => {
    if (isLoaded) {
      resolve(null);

      return;
    }

    const element: any = document.createElement('script');
    const attributes = {
      id: `kilo-script-${btoa(src)}`,
      src,
    };

    Object.entries(attributes).forEach(([name, value]: [string, string]) => {
      if (value) {
        element[name] = value;
      }
    });

    element.onload = function () {
      isLoaded = true;
      resolve(null);
    };

    document.head.appendChild(element);
  });

const createUi = async (
  urlConfigs: any,
  product: Product,
  code: string,
  callbacks: any,
) => {
  try {
    if (typeof (window as any).kiloCheckout !== 'undefined') {
      const finalPrice =
        product.trials.length > 0
          ? product.trials[0].amount
          : product.prices[0].final_price;
      const paymentProduct = new (window as any).KiloProduct(
        product.key,
        product.name,
        finalPrice,
      );

      if (urlConfigs.prepare_urls) {
        Object.keys(urlConfigs.prepare_urls).map(async url => {
          await (window as any).kiloCheckout.create(
            urlConfigs.prepare_urls[url],
            {
              product: paymentProduct,
              clientCode: code,
              selector: `#kilo-payment-${url}`,
              callbacks,
            },
          );
        });
      }
    }
  } catch (error) {
    callbacks.onError(error);
  }
};

export const initialize = async (
  config: any,
  product: Product,
  code: string,
  callbacks: any,
) => {
  const urlConfigs: GatewayProps | undefined = config?.gateway;
  const style = document.createElement('link');

  style.setAttribute('type', 'text/css');
  style.setAttribute('rel', 'stylesheet');

  if (urlConfigs && urlConfigs.style) {
    style.setAttribute('href', urlConfigs?.style);
  }

  document.head.appendChild(style);

  if (config.gateway?.main) {
    // eslint-disable-next-line promise/catch-or-return
    loadScript(config.gateway.main).then(() => {
      createUi(config.gateway, product, code, callbacks);
    });
  }
};

export const replaceLocaleParam = (
  inputObject: Record<string, string>,
  oldLocale: string,
  newLocale: string,
): Record<string, string> => {
  const updatedObject: Record<string, string> = {};

  Object.keys(inputObject).forEach(key => {
    updatedObject[key] = inputObject[key].replace(
      `p_locale=${oldLocale}`,
      `p_locale=${newLocale}`,
    );
  });

  return updatedObject;
};
