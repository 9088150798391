import { UpsellKey, User } from 'types';

export const getPhotoEnhancerQuantity = (userData: User) => {
  if (!userData.client || !userData.client?.orders) return null;

  const photoEnhancerUpsell = userData.client.orders.find(order =>
    order.items?.find(item => item.product.key.includes('photo-enhancer')),
  );

  if (!photoEnhancerUpsell) return null;

  const { key } = photoEnhancerUpsell.items[0].product;

  if (key === UpsellKey.UpsellPhotoEnhancer50) return 50;

  if (key === UpsellKey.UpsellPhotoEnhancer10) return 10;

  return 'infinite';
};
