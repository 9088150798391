import type { FC, ReactNode } from 'react';
import { UserContextProvider } from 'state';

interface Props {
  children: ReactNode;
}

export const StateProviders: FC<Props> = ({ children }) => (
  <UserContextProvider>{children}</UserContextProvider>
);
