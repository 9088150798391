import { useRouter } from 'hooks';
import { TEXTS } from 'data';

export const useLocalisation = () => {
  const { query } = useRouter();

  let lang = 'en';

  if (query.lang === 'es' || query.lang === 'de' || query.lang === 'fr') {
    lang = query.lang;
  }

  const localizeTexts = (
    texts: Record<string, any>,
    lang: string,
  ): Record<string, any> => {
    return Object.keys(texts).reduce((acc, key) => {
      if (typeof texts[key] === 'object' && !texts[key][lang]) {
        // If the value is an object (e.g., nested page texts), recurse
        acc[key] = localizeTexts(texts[key], lang);
      } else {
        acc[key] = texts[key][lang];
      }
      return acc;
    }, {} as Record<string, any>);
  };

  return { t: localizeTexts(TEXTS, lang) };
};
