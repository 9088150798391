import { Flex, Text } from '@chakra-ui/react';
import { useLocalisation } from 'hooks';
import { FC } from 'react';

interface Props {
  discount: string;
}

export const DiscountBlackFriday: FC<Props> = ({ discount }) => {
  const { t } = useLocalisation();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bgColor="black"
      p="0.45rem 1rem"
      border="2px dashed #FFB422"
    >
      <Text
        textStyle="caption12"
        color="#FFB422"
        fontWeight="700"
        textAlign="center"
      >
        {t.discountBlackFriday.text1}{' '}
        <Text
          as="span"
          display={{ base: 'block', md: 'inline-block' }}
          color="neutral.0"
        >
          {t.discountBlackFriday.text2} {discount}
        </Text>
      </Text>
    </Flex>
  );
};
