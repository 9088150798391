import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'theme';
import { Scripts } from 'components';
import { StateProviders } from 'state';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../styles.css';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App({ Component, pageProps }: AppProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={<p>Something went wrong. Please try again later.</p>}
    >
      <QueryClientProvider client={queryClient}>
        <StateProviders>
          <ChakraProvider theme={theme}>
            <Scripts />
            <Component {...pageProps} />
          </ChakraProvider>
        </StateProviders>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
