import type { FC, HTMLInputTypeAttribute } from 'react';
import {
  Input as BaseInput,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types/fields';

interface Props<TFieldValues extends FieldValues = FieldValues> {
  name: string;
  label?: string;
  control: Control<TFieldValues>;
  type?: HTMLInputTypeAttribute;
  defaultValue?: string | number;
  error?: string;
  inputLeftAddonText?: string;
  placeholder?: string;
}

export const Input: FC<Props> = ({
  error,
  defaultValue,
  name,
  label,
  type,
  inputLeftAddonText,
  control,
  placeholder = ' ',
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, value, ref } }) => (
        <FormControl
          isInvalid={Boolean(error)}
          position="relative"
          variant="floating"
        >
          <BaseInput
            type={type}
            ref={ref}
            value={value}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            sx={{
              borderRadius: '0.5rem',
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'purple.500',
              backgroundColor: 'transparent',
              color: 'white',
              fontSize: '1.25rem',
            }}
            _focus={{
              boxShadow: 'none',
              borderColor: 'purple.400',
            }}
            _hover={{
              boxShadow: 'none',
              borderColor: 'purple.400',
            }}
            _invalid={{
              boxShadow: 'none',
              borderColor: 'system.error',
            }}
            _placeholder={{
              color: 'purple.600',
            }}
          />
          {label && (
            <FormLabel color="purple.500 !important">{label}</FormLabel>
          )}
          {inputLeftAddonText && (
            <Box
              sx={{
                zIndex: 2,
                position: 'absolute',
                right: '1rem',
                top: '1rem',
              }}
            >
              <Text fontSize={{ md: '1.25rem' }} color="white">
                {inputLeftAddonText}
              </Text>
            </Box>
          )}
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
      )}
    />
  );
};
