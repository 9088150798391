import {
  ENV,
  FACEBOOK_API_ACCESS_TOKEN,
  FACEBOOK_PIXEL_ID,
  GTM_TOKEN,
  REDDIT_PIXEL_ID,
} from 'config';
import {
  ConversionsAPIEventName,
  Env,
  FacebookEventName,
  GtmEventName,
  PlausibleEventName,
  Product,
  UserDataProps,
} from 'types';
import { getCookiesByKeys, hashStringToSHA256 } from 'utils';
import { useContext } from 'react';
import { UserContext } from 'state';

export const useTracking = () => {
  const { user } = useContext(UserContext);

  const constructItemData = (product: Product) => {
    const price = getProductFinalPrice(product);
    const productId = product.sku;
    const productName = product.name;

    return {
      item_id: productId,
      item_name: productName,
      index: 0,
      item_brand: 'Memowrite',
      item_category: 'Digital Subscription',
      price,
      quantity: 1,
    };
  };

  const getProductFinalPrice = (product: Product) => {
    return product.trials.length > 0
      ? product.trials[0].amount
      : product.prices[0].final_price;
  };

  const trackGtmEvent = (
    eventName: GtmEventName,
    data?: Record<string, any>,
  ) => {
    if (!GTM_TOKEN) return;

    data
      ? window?.gtag('event', eventName, data)
      : window?.gtag('event', eventName);
  };

  const trackFacebookEvent = (
    eventName: FacebookEventName,
    data?: Record<string, any>,
  ) => {
    if (!FACEBOOK_PIXEL_ID) return;

    data
      ? window?.fbq('track', eventName, data)
      : window?.fbq('track', eventName);
  };

  const trackRedditEvent = (
    eventName: FacebookEventName,
    data?: Record<string, any>,
  ) => {
    if (!REDDIT_PIXEL_ID) return;

    data
      ? window?.rdt('track', eventName, data)
      : window?.rdt('track', eventName);
  };

  const trackPlausibleEvent = (
    eventName: PlausibleEventName,
    data?: Record<string, any>,
  ) => {
    if (ENV !== Env.Production) return;

    data ? window?.plausible(eventName, data) : window?.plausible(eventName);
  };

  const trackConversionsApiEvent = async (
    eventName: ConversionsAPIEventName,
    data?: Record<string, any>,
  ) => {
    if (!FACEBOOK_API_ACCESS_TOKEN || !FACEBOOK_PIXEL_ID) return;

    const timeStamp = Math.round(new Date().getTime() / 1000);
    const currentURL = window.location.href;
    const cookies = getCookiesByKeys(['_fbc', '_fbp']);
    const baseData = {
      event_name: eventName,
      event_time: timeStamp,
      action_source: 'website',
      event_source_url: currentURL,
      data_processing_options_country: 0,
      data_processing_options_state: 0,
    };
    const userData = {
      email: user?.email,
      userCode: user?.code,
    };
    const hashedUserData: UserDataProps = {};
    const userDataKeys = Object.keys(userData);

    for (const key of userDataKeys) {
      const value = userData[key as keyof UserDataProps] || '';
      const hashedValue = await hashStringToSHA256(value);
      Object.assign(hashedUserData, { [key]: hashedValue });
    }

    const payload = {
      data: [
        {
          ...baseData,
          user_data: {
            em: [hashedUserData?.email ?? null],
            fbp: cookies._fbp ?? null,
            fbc: cookies._fbc ?? null,
            client_user_agent: window.navigator.userAgent,
            external_id: [hashedUserData?.userCode ?? null],
          },
          custom_data: data,
        },
      ],
    };

    try {
      await fetch(
        `https://graph.facebook.com/v16.0/${FACEBOOK_PIXEL_ID}/events?access_token=${FACEBOOK_API_ACCESS_TOKEN}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );
    } catch (e) {
      console.error(e);
    }
  };

  const trackFirstVisit = (product: Product) => {
    const data = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructItemData(product)],
    };

    trackGtmEvent(GtmEventName.BeginCheckout, data);
    trackFacebookEvent(FacebookEventName.AddToCart);
    trackRedditEvent(FacebookEventName.AddToCart);
    trackConversionsApiEvent(ConversionsAPIEventName.InitiateCheckout, data);
    trackPlausibleEvent(PlausibleEventName.Checkout);
  };

  const trackLeadCreation = (product: Product, email: string) => {
    const data = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      email,
    };
    trackGtmEvent(GtmEventName.GenerateLead, data);
    trackFacebookEvent(FacebookEventName.Lead);
    trackRedditEvent(FacebookEventName.Lead);
    trackConversionsApiEvent(ConversionsAPIEventName.Lead, data);
  };

  const trackAddPaymentsInfo = (product: Product) => {
    const data = {
      currency: product.prices[0].currency_id,
      value: getProductFinalPrice(product),
      items: [constructItemData(product)],
    };

    trackGtmEvent(GtmEventName.AddPaymentInfo, data);
    trackConversionsApiEvent(ConversionsAPIEventName.AddToCart, data);
  };

  const trackPaymentAttempt = () => {
    trackGtmEvent(GtmEventName.PaymentAttempt);
  };

  const trackSuccessfulPurchase = (
    product: Product,
    userCode: string,
    orderId: number,
  ) => {
    const value = getProductFinalPrice(product);
    const productId = product.sku;
    const productName = product.name;
    const data = {
      value,
      currency: product.prices[0].currency_id,
      transaction_id: orderId,
      items: [constructItemData(product)],
    };
    const plausibleData = {
      revenue: {
        currency: product.prices[0].currency_id,
        amount: value,
      },
    };

    trackGtmEvent(GtmEventName.Purchase, data);
    trackFacebookEvent(FacebookEventName.Purchase, {
      value: value.toFixed(2),
      currency: product.prices[0].currency_id,
    });
    trackRedditEvent(FacebookEventName.Purchase, {
      products: [
        {
          id: productId,
          name: productName,
          category: productName,
        },
      ],
      conversionId: userCode,
      currency: product.prices[0].currency_id,
      itemCount: 1,
      value: value.toFixed(2),
    });
    trackConversionsApiEvent(ConversionsAPIEventName.Purchase, data);
    trackPlausibleEvent(PlausibleEventName.Purchase, plausibleData);
  };

  const trackUpsellItemSelection = () => {
    trackGtmEvent(GtmEventName.SelectItemUpsell);
  };

  const trackUpsellPurchase = (eventName: GtmEventName) => {
    trackGtmEvent(eventName);
  };

  const trackUpsellDecline = (eventName: GtmEventName) => {
    trackGtmEvent(eventName);
  };

  return {
    trackFirstVisit,
    trackLeadCreation,
    trackAddPaymentsInfo,
    trackPaymentAttempt,
    trackSuccessfulPurchase,
    trackUpsellItemSelection,
    trackUpsellPurchase,
    trackUpsellDecline,
  };
};
