export const textStyles = {
  XL5: {
    fontSize: ['1.875rem', '1.875rem', '3rem', '3rem'],
    fontWeight: 600,
    lineHeight: ['2.25rem', '2.25rem', '4.125rem', '4.125rem'],
    fontFamily: 'Inter',
  },
  XL3: {
    fontSize: ['1.75rem', '1.75rem', '2.5rem', '2.5rem'],
    fontWeight: 400,
    lineHeight: ['2.25rem', '2.25rem', '3rem', '3rem'],
    fontFamily: 'Inter',
  },
  XL2: {
    fontSize: ['1.5rem', '1.5rem', '2rem', '2rem'],
    fontWeight: 400,
    lineHeight: ['2rem', '2rem', '2.75rem', '2.75rem'],
    fontFamily: 'Inter',
  },
  XL: {
    fontSize: ['1.5rem', '1.5rem', '1.75rem', '1.75rem'],
    fontWeight: 400,
    lineHeight: ['1.75rem', '1.75rem', '2rem', '2rem'],
    fontFamily: 'Inter',
  },
  L: {
    fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.5rem'],
    fontWeight: 400,
    lineHeight: ['1.5rem', '1.5rem', '2rem', '2rem'],
    fontFamily: 'Inter',
  },
  M: {
    fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem'],
    fontWeight: 400,
    lineHeight: ['1.75rem', '1.75rem', '1.75rem', '1.75rem'],
    fontFamily: 'Inter',
  },
  S: {
    fontSize: ['1.125rem', '1.125rem', '1.125rem', '1.125rem'],
    fontWeight: 400,
    lineHeight: ['1.75rem', '1.75rem', '1.75rem', '1.75rem'],
    fontFamily: 'Inter',
  },
  body16: {
    fontSize: ['0.875rem', '0.875rem', '1rem', '1rem'],
    fontWeight: 400,
    lineHeight: ['1.25rem', '1.25rem', '1.5rem', '1.5rem'],
    fontFamily: 'Inter',
  },
  body14: {
    fontSize: ['0.75rem', '0.75rem', '0.875rem', '0.875rem'],
    fontWeight: 400,
    lineHeight: ['1.125rem', '1.125rem', '1.25rem', '1.25rem'],
    fontFamily: 'Inter',
  },
  caption12: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    fontFamily: 'Inter',
  },
  caption10: {
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: '1rem',
    fontFamily: 'Inter',
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '1.125rem',
    color: 'neutral.500',
    letterSpacing: '-0.0037rem',
    textTransform: 'uppercase',
  },
};
