export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export enum DateFormat {
  Year = 'yyyy-MM-dd',
  Day = 'dd',
  DayShorthand = 'EEE',
}
