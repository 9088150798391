export interface User {
  code: string;
  email: string;
  client?: {
    orders?: {
      items: {
        product: {
          key: string;
        };
      }[];
    }[];
  };
}

export interface FirebaseUser {
  email: string;
  uid: string;
  accessToken: string;
  refreshToken: string;
}

export enum StorageKey {
  User = 'user',
  Product = 'product',
}
