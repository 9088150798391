const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-0.5rem)',
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  color: 'neutral.600',
};

export const Form = {
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        input: {
          _invalid: {
            '+ label': {
              color: 'system.error',
            },
          },
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            ...activeLabelStyles,
          },
        label: {
          color: 'pink.900',
          top: '1rem',
          left: '1rem',
          zIndex: 2,
          margin: 'unset',
          position: 'absolute',
          pointerEvents: 'none',
          transformOrigin: 'left top',
        },
      },
    },
  },
};
