import { textStyles } from 'theme/styles';

export const Button = {
  sizes: {
    sm: {
      ...textStyles.body16,
      p: '0.5625rem 0.75rem',
      borderRadius: '3rem',
      height: 'auto',
      fontWeight: 600,
    },
    lg: {
      ...textStyles.body16,
      py: '0.75rem',
      px: '1.5rem',
      fontWeight: 600,
      borderRadius: '3rem',
      height: 'auto',
    },
  },
  variants: {
    primary: {
      bg: 'main.700',
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: '1.5rem',
      color: 'white',
      py: '0.5rem',

      _hover: {
        transform: 'scale(1.035)',
        _disabled: {
          bg: 'primary.500',
          color: 'white',
          transform: 'scale(1)',
        },
      },

      _disabled: {
        bg: 'primary.500',
        color: 'white',
        opacity: 0.2,
      },
    },
    transparent: {
      bg: 'white',
      border: '1px solid',
      borderColor: 'main.700',
      borderRadius: '1.5rem',
      color: 'main.700',
      py: '0.5rem',

      _hover: {
        transform: 'scale(1.035)',
        _disabled: {
          bg: 'primary.500',
          color: 'white',
          transform: 'scale(1)',
        },
      },

      _disabled: {
        bg: 'primary.500',
        color: 'white',
        opacity: 0.2,
      },
    },
    grey: {
      bg: 'haze',
      border: 0,
      borderRadius: '1.5rem',
      color: 'white',
      py: '0.5rem',

      _hover: {
        transform: 'scale(1.035)',
        _disabled: {
          bg: 'primary.500',
          color: 'white',
          transform: 'scale(1)',
        },
      },

      _disabled: {
        bg: 'primary.500',
        color: 'white',
        opacity: 0.2,
      },
    },
    black: {
      bg: 'black',
      border: 0,
      borderRadius: '1.5rem',
      color: 'white',
      py: '0.5rem',

      _hover: {
        transform: 'scale(1.035)',
        _disabled: {
          bg: 'primary.500',
          color: 'white',
          transform: 'scale(1)',
        },
      },

      _disabled: {
        bg: 'primary.500',
        color: 'white',
        opacity: 0.2,
      },
    },
    red: {
      bg: 'red.400',
      border: 0,
      borderRadius: '1.5rem',
      color: 'white',
      py: '0.5rem',

      _hover: {
        transform: 'scale(1.035)',
        _disabled: {
          bg: 'primary.500',
          color: 'white',
          transform: 'scale(1)',
        },
      },

      _disabled: {
        bg: 'primary.500',
        color: 'white',
        opacity: 0.2,
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'primary',
  },
};
