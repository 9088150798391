import { useCountdown, useLocalisation } from 'hooks';
import { formatNumberToDwoDigits } from 'utils';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { TimeUnit } from './TimeUnit';

export const CountdownOrangeLong = () => {
  const targetDate = useMemo(() => new Date(new Date().getTime() + 600000), []);
  const { minutes, seconds } = useCountdown(targetDate);
  const { t } = useLocalisation();

  return (
    <Box
      bgColor="yellow.400"
      display="flex"
      alignItems="center"
      px="1rem"
      py="0.45rem"
      borderRadius="0.5rem"
      alignSelf="center"
      w="100%"
    >
      <Flex gap="0.25rem" alignItems="center" justifyContent="center" mx="auto">
        <Text textStyle="body16" color="black">
          {t.timer.text3}
        </Text>
        <Flex>
          <TimeUnit
            dividerIncluded
            time={formatNumberToDwoDigits(minutes)}
            textColor="black"
          />
          <TimeUnit time={formatNumberToDwoDigits(seconds)} textColor="black" />
        </Flex>
      </Flex>
    </Box>
  );
};
