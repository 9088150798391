import type { ComponentType } from 'react';
import type { GetServerSidePropsContext, Redirect } from 'next/types';
import { User } from 'types';

import type { RouteParams } from './routes';

export enum Env {
  Development = 'development',
  Production = 'production',
}

export enum Funnel {
  Main = 'main',
  BlackFriday = 'black-friday',
  BlackFridayGift = 'black-friday-gift',
  Gift = 'gift',
  Xmas = 'xmas',
  XmasGift = 'xmas-gift',
}

export type PageProps = {
  funnel: Funnel;
} & {
  [key: string]: any;
};

export interface RegisterPageProps extends PageProps {
  userData: User;
}

export interface PaymentsPageProps extends PageProps {
  userData: User;
  config: User;
}

export type GetServerSideProps<TPageProps extends PageProps = PageProps> = (
  context: GetServerSidePropsContext<RouteParams>,
) => Promise<GetServerSidePropsResult<TPageProps>>;

export interface GetServerSidePropsResult<TPageProps> {
  props?: TPageProps | Promise<TPageProps>;
  redirect?: Redirect;
  notFound?: true;
}

export type FunnelToViewMap<T = unknown> = {
  [key in Funnel]: ComponentType<PageProps & T>;
};

export enum QueryParam {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmTerm = 'utm_term',
}

export enum MeasurementSystem {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum BmiCategory {
  Underweight = 'underweight',
  Normal = 'normal',
  Overweight = 'overweight',
  Obese = 'obese',
}
