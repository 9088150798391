import type { Page } from 'types';
import { Funnel, RouteParams, Routes } from 'types';
import { GetServerSidePropsContext } from 'next/types';

export const redirectToDefaultProduct = (
  context: GetServerSidePropsContext<RouteParams>,
  defaultProductKey: string,
) => {
  const { query, resolvedUrl } = context;

  const updatedQuery = {
    ...query,
    p: defaultProductKey,
  };

  const url = new URL(`https://${context.req.headers.host}${resolvedUrl}`);
  Object.keys(updatedQuery).forEach(key =>
    url.searchParams.set(key, (updatedQuery as any)[key]),
  );

  return {
    redirect: {
      destination: url.pathname + url.search,
      permanent: true,
    },
  };
};

export const buildPathname = (
  page: Page,
  routeParams: { [key: string]: string } = {},
) => {
  const funnel = routeParams.funnel;
  let pathname: string =
    funnel && funnel !== Funnel.Main ? Routes[page].funnel : Routes[page]._;

  for (const key of Object.keys(routeParams)) {
    pathname = pathname.replaceAll(`[${key}]`, routeParams[key]);
  }

  return pathname;
};

export const buildLinkTo = (
  page: Page,
  routeParams: { [key: string]: string } = {},
  queryParams: { [key: string]: string } = {},
) => {
  let link: string = buildPathname(page, routeParams);

  const queryString = new URLSearchParams(queryParams).toString();

  if (queryString) {
    link = `${link}?${queryString}`;
  }

  return link;
};

export const getPageByRoute = (
  route: string,
  routeParams: { [key: string]: string } = {},
) => {
  return Object.keys(Routes).find(page => {
    return Object.values(Routes[page as Page]).some(it => {
      let resolvedPathname = it;

      for (const key of Object.keys(routeParams)) {
        resolvedPathname = resolvedPathname.replaceAll(
          `[${key}]`,
          routeParams[key],
        );
      }

      return route === it || route === resolvedPathname;
    });
  }) as Page | undefined;
};
