export interface Product {
  description: string | null;
  has_trials: boolean;
  id: number;
  is_subscription: boolean;
  is_upsell: boolean;
  key: string;
  name: string;
  plan: Plan;
  prices: Price[];
  properties: Property[];
  sku: string;
  trials: TrialPrice[];
  type: ProductType;
}

export enum UpsellKey {
  UpsellMemoryRecall = 'upsell-memory-recall',
  UpsellPhotoEnhancer = 'upsell-photo-enhancer',
  UpsellPhotoEnhancer10 = 'upsell-photo-enhancer-10',
  UpsellPhotoEnhancer50 = 'upsell-photo-enhancer-50',
  UpsellAdditionalBooks = 'upsell-additional-books',
}

export interface Upsells {
  data: Product[];
  upsell_urls: Record<UpsellKey, string>;
}

interface TrialPrice {
  amount: number;
  currency: string;
  duration: number;
  duration_type: TimeUnit;
  locale: string;
  old_amount: number;
}

interface Property {
  name: string;
  value: string;
}

export interface Plan {
  key: string;
  duration: number;
  type: TimeUnit;
  installments: string;
}

export interface Price {
  currency_id: string;
  discount: number;
  discount_percent: number;
  final_price: number;
  locale: string;
  price: number;
}

enum ProductType {
  Digital = 'digital',
  Physical = 'physical',
}

enum TimeUnit {
  Months = 'months',
  Years = 'years',
}
