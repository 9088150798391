import { useDisclosure } from '@chakra-ui/react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { RndApi } from 'services';
import type { User } from 'types';
import type { FieldValues } from 'react-hook-form';
import {
  firebaseAuth,
  firebaseDatabase,
  FIRESTORE_DATABASE_NAME,
  SUPPORT_EMAIL,
} from 'config';
import { getPhotoEnhancerQuantity } from 'utils';

export const useAuth = (userData: User, isForKiloUser?: boolean) => {
  const {
    isOpen: isSuccessModalOpen,
    onOpen: onSuccessModalOpen,
    onClose: onSuccessModalClose,
  } = useDisclosure();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isRegistrationSuccessful, setRegistrationSuccessful] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');
  let rndApiUserCode = userData?.code;

  const registerUser = async (data: FieldValues) => {
    const { userEmail, userPassword } = data;

    try {
      setLoading(true);
      setErrorMessage(null);

      if (isForKiloUser) {
        try {
          const leadResponse = await RndApi.createLead({ email: userEmail });
          rndApiUserCode = leadResponse.code;
        } catch (error) {
          setErrorMessage(
            `Couldn't set data for your account. Please contact support: ${SUPPORT_EMAIL}`,
          );

          return;
        }
      }

      const response = await createUserWithEmailAndPassword(
        firebaseAuth,
        userEmail,
        userPassword,
      );

      try {
        await RndApi.createExternalId(
          rndApiUserCode,
          'firebase_uid',
          response.user.uid,
        );
        await writeUserData(userEmail, response.user.uid);
      } catch (error) {
        setErrorMessage(
          `Couldn't set data for your account. Please contact support: ${SUPPORT_EMAIL}`,
        );

        return;
      }

      setRegisteredEmail(userEmail);
      setRegistrationSuccessful(true);
      onSuccessModalOpen();
    } catch {
      setErrorMessage(`User with your email: ${userEmail} already exists`);
    } finally {
      setLoading(false);
    }
  };

  const writeUserData = async (email: string, uid: string) => {
    const usersRef = doc(firebaseDatabase, FIRESTORE_DATABASE_NAME, uid);
    const data = {
      access: true,
      email,
      code: rndApiUserCode,
      platform: 'app',
      photo_enhancer: getPhotoEnhancerQuantity(userData),
    };
    setDoc(usersRef, data);
  };

  return {
    errorMessage,
    isLoading,
    isSuccessModalOpen,
    isRegistrationSuccessful,
    registeredEmail,
    onSuccessModalClose,
    registerUser,
  };
};
