import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Icon } from 'components';
import { useLocalisation } from 'hooks';

interface Props {
  isPaymentsPage?: boolean;
}

export const Breadcrumbs: FC<Props> = ({ isPaymentsPage = false }) => {
  const { t } = useLocalisation();

  return (
    <Flex gap="1rem" alignItems="center">
      <Text textStyle="body16" color={isPaymentsPage ? 'neutral.900' : 'black'}>
        {t.breadcrumbs.text1}
      </Text>
      <Box pt="0.15rem">
        <Icon src="ChevronRight" />
      </Box>
      <Text textStyle="body16" color={isPaymentsPage ? 'black' : 'neutral.900'}>
        {t.breadcrumbs.text2}
      </Text>
      <Box pt="0.15rem">
        <Icon src="ChevronRight" />
      </Box>
      <Text textStyle="body16" color="neutral.900">
        {t.breadcrumbs.text3}
      </Text>
    </Flex>
  );
};
