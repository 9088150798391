export const CURRENCY_SYMBOLS_MAP = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export interface GatewayProps {
  main: string;
  prepare_url: string;
  prepare_urls: { [key: string]: string };
  style?: string;
}

export interface PaymentsSuccessPayload {
  order_id: number;
  url: string;
}

export enum PaymentLocale {
  English = 'en',
  Germany = 'de',
  GreatBritain = 'gb',
}

export enum LanguageLocale {
  English = 'en',
  French = 'fr',
  German = 'de',
  British = 'gb',
  Italian = 'it',
}

export enum CurrencyId {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}
